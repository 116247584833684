import logo from "./logo.svg";
import FluentStory from "fluent-story";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";

const Component = () => {
	return (
		<header className="App-header">
			<img src={logo} className="App-logo" alt="logo" />
			<p>
				Edit <code>src/App.js</code> and save to reload.
			</p>
			<a
				className="App-link"
				href="https://reactjs.org"
				target="_blank"
				rel="noopener noreferrer">
				Learn React
			</a>
		</header>
	);
};

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Switch>
					<Route
						exact
						path="/stories"
						render={() => <FluentStory stories={{ Component }} />}
					/>
					<Route exact path="/" component={Component} />
				</Switch>
			</BrowserRouter>
		</div>
	);
}

export default App;
